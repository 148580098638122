<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <b-button
        v-b-toggle.collapse-1
        variant="outline-primary"
      >
        {{ t('Filters') }}
      </b-button>
      <b-button
        variant="primary"
        @click="openModal('add')"
      >
        <feather-icon icon="PlusIcon" />
        {{ t('Add') }}
      </b-button>
    </div>
    <b-collapse
      id="collapse-1"
      class="mt-2"
    >
      <process-filter
        :filterparams="form"
        @searchData="GetSearchFilter"
      />
    </b-collapse>
    <b-card>
      <b-table
        sticky-header
        responsive="true"
        :items="ProcessList.data"
        :fields="userFields"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template #cell(status)="data">
          <span
            v-if="data.item.status === 1"
            class="text-success cursor-pointer"
            @click="changeStatus(data.item)"
          >Active</span>
          <span
            v-if="data.item.status === 0"
            class="text-danger cursor-pointer"
            @click="changeStatus(data.item)"
          >In-Active</span>
        </template>
      </b-table>
      <b-row v-if="ProcessList.total">
        <b-col
          cols="4"
          class="d-flex"
        >
          <div class="align-self-center pr-2">
            {{ t('Total Entries') + " " + ProcessList.total }}
          </div>
          <b-form-group class="align-self-center mb-0">
            <label class="d-inline-block text-sm-left mr-50">{{ t('Per page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="form.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getProcessList"
            />
          </b-form-group>

        </b-col>
        <b-col cols="8">
          <pagination
            :data="ProcessList"
            :limit="2"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>
      </b-row>
    </b-card>
    <vue-context
      ref="processContextMenu"
    >
      <li>
        <b-link
          class="d-flex align-items-center"
          @click="openModal('edit')"
        >
          <feather-icon
            icon="EditIcon"
            size="16"
          />
          <span class="ml-75">{{ t('Edit') }}</span>
        </b-link>
      </li>
    </vue-context>
    <create-process
      :process-data="processData"
    />
  </div>
</template>
<script>
import {
  BButton, BCard, BCollapse, VBToggle,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import VueContext from 'vue-context'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@/utils/axiosCompany'
import store from '@/store'
import constants from '@/constants'
import ProcessFilter from './ProcessFilter.vue'
import CreateProcess from './CreateProcess.vue'
import { tableFilter, showLoader, hideLoader } from '@/utils/common'

export default {
  components: {
    BButton,
    BCard,
    BCollapse,
    ProcessFilter,
    CreateProcess,
    VueContext,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      tableFilter,
      valuesContext: {},
      form: {
        size: 10,
        sortBy: 'id',
        sortOrder: 'desc',
        title: '',
        status: 1,
        // start_date: null,
        // end_date: null,
        // period: '',
      },
      ProcessList: [],
      processData: [],
    }
  },
  computed: {
    userFields() {
      return [
        { key: 'id', label: this.$i18n.t('Id'), sortable: true },
        { key: 'title', label: this.$i18n.t('Process'), sortable: true },
        { key: 'status', label: this.$i18n.t('Status'), sortable: true },
      ]
    },
  },
  mounted() {
    this.$root.$on('refreshProcessList', () => {
      this.getResults()
    })
  },
  methods: {
    contextmenu(item, index, event) {
      event.preventDefault()
      this.valuesContext = item
      this.$refs.processContextMenu.open(event, item)
    },
    sortChanged(val) {
      this.form.sortBy = val.sortBy
      this.form.sortOrder = val.sortDesc ? 'desc' : 'asc'
      this.getProcessList()
    },
    async getResults(page = 1) {
      showLoader()
      // eslint-disable-next-line no-undef
      await axios.post(`/polymer-process/list?page=${page}`, this.form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.ProcessList = response.data.data
          hideLoader()
        })
      hideLoader()
    },
    GetSearchFilter(val) {
      this.form = val
      this.getProcessList()
    },
    async getProcessList() {
      showLoader()
      await store.dispatch('SettingStore/getProcessList', this.form).then(response => {
        if (response.data.code === constants.SUCCESS) {
          this.ProcessList = response.data.data
          // eslint-disable-next-line eqeqeq
        } else if (response.data.code == constants.ERROR_500) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Something Went Wrong!',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      }).catch(e => {
      // eslint-disable-next-line eqeqeq
        if (e.msg == 'Unauthenticated.') {
          this.$router.back()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'No Permission',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Something Went Wrong!',
              icon: 'Danger',
              variant: 'danger',
              text: '',
            },
          })
        }
      })
      hideLoader()
    },
    openModal(type) {
      this.processData = []
      if (type === 'add') {
        this.$bvModal.show('CreateProcessModal')
      }
      if (type === 'edit') {
        showLoader()
        this.processData = this.valuesContext
        this.$bvModal.show('CreateProcessModal')
        hideLoader()
      }
    },
    changeStatus(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure ?'),
        text: data.status === 1 ? this.$i18n.t('You want to In-Activate Status') : this.$i18n.t('You want to Activate Status'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$i18n.t('Cancel'),
        confirmButtonText: this.$i18n.t('Yes'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.onSubmit(data)
        }
      })
    },
    async onSubmit(data) {
      showLoader()
      await store.dispatch('SettingStore/CreateProcess',
        {
          id: data.id,
          title: data.title,
          status: data.status === 1 ? 0 : 1,
        }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.$swal({
            icon: 'success',
            title: this.$i18n.t('Success'),
            text: this.$i18n.t('Status is successfully Updated'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.getProcessList()
          hideLoader()
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
